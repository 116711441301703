.menu-header {
	margin-bottom: 10px;
	display: inline-block;
	font-weight: bold;
	transition: height 0.3s linear;
	height: 17px;
	overflow: hidden;
}

.MuiButtonBase-root[title="Dashboard"] ~ div > .menu-header {
	height: 0px;
	transition: height 0.3s linear;
}

pre.code {
	white-space: pre-wrap;
	background: rgba(0,0,0,0.2);
}

table.doc-desc td {
	padding: 5px 20px 5px 0px;
}

table.doc-desc > tr > td {
	padding: 5px 10px;
}

table.doc-desc > tr {
	margin: 3px 0px;
	background: rgba(0,0,0,0.2);	
}

table.doc-desc {
	border-left: 4px solid #00cfff;
	padding-left: 8px;
}

div.doc h2 {
	color: #00cfff;
}
div.doc h1 {
	background: #1a0549;
    display: inline-block;
    padding: 4px 16px;
    position: relative;
    left: -16px;
    border-radius: 0px 5px 5px 0px;
}